import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { DynamicDashboardStyles } from './styles';
import { analyticsApi, ApiCsi, ApiDynamic, fieldsApi, TPostCsi, TPostDynamic } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { CurvedChart } from '../../analyticsCharts';
type TDynamicDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#6EAE93', '#A7C5E9', '#F5EF67', '#BB6685'];
export const DynamicDashboard: FC<TDynamicDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ApiDynamic>();
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostDynamic = {
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      csiFieldIDs: [858866],
      npsFieldIDs: [858818],
      groupsByDates: 'month'
    };
    setLoading(true);
    analyticsApi.getDynamic(+companyID, filteredData).then(res => {
      console.log(res.data);
      setData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/dynamic/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      npsNegativeAnswers: ['0', '1', '2', '3', '4', '5', '6', '0/10', '1/10', '2/10', '3/10', '4/10', '5/10', '6/10'],
      npsPositiveAnswers: ['9', '10', '9/10', '10/10'],
      csiPositiveAnswers: ['Чудово', 'Добре'],
      csiFieldIDs: [858866],
      npsFieldIDs: [858818],
      groupsByDates: 'month'
    }));
  }, [reQuery]);
  if (loading) {
    return <DynamicDashboardStyles>
        <Loader />
      </DynamicDashboardStyles>;
  }
  return <DynamicDashboardStyles>
      <div className="charts">
        <div className="charts-item">
          <h3 className="charts-title">Динаміка CSI</h3>
          {data && <CurvedChart data={data?.csi.map((el, index) => ({
          x: index,
          y: el.csi,
          label: el.groupName
        }))} width={dimensions.width} height={dimensions.height / 4} />}
        </div>
        <div className="charts-item">
          <h3 className="charts-title">Динаміка NPS</h3>
          {data && <CurvedChart data={data?.nps.map((el, index) => ({
          x: index,
          y: el.nps,
          label: el.groupName
        }))} width={dimensions.width} height={dimensions.height / 4} hasNegativeValues />}
        </div>
        <div className="charts-item">
          <h3 className="charts-title">Динаміка кількості відгуків</h3>
          {data && <CurvedChart data={data?.reviewsCount.map((el, index) => ({
          x: index,
          y: el.count,
          label: el.groupName
        }))} width={dimensions.width} height={dimensions.height / 4} />}
        </div>
      </div>
    </DynamicDashboardStyles>;
};