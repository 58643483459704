import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { LocationsOutOfRatingStyles } from './styles';
import { analyticsApi, ApiLocationsCounts, ApiLocationsData, TPostLocationsOutOfRating } from '../../../../api';
import { Table } from '../../../organisms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TLocationsOutOfRating = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const LocationsOutOfRating_1 = _compiledBlock(_props => _props.v0, {
  name: "LocationsOutOfRating_1",
  portals: ["v0"]
});
export const LocationsOutOfRating: FC<TLocationsOutOfRating> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiLocationsData[]>([]);
  const [counts, setCounts] = useState<ApiLocationsCounts>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      quarter,
      year,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostLocationsOutOfRating = {
      year: +year as number,
      quarter: +quarter as number,
      fieldIDs: [858718, 858809],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getLocationOutOFating(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      setData(prev => [...prev, ...res.data.data]);
      setCounts(res.data.counts);
      dataCount.current = res.count ? res.count : 0;
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/rating/out-of-csi/file'));
    dispatch(setSaveFilters({
      year: +year as number,
      quarter: +quarter as number,
      fieldIDs: [858718, 858809],
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Назва центру</span>
          </div>,
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'Тип ЦНАП',
      accessor: 'rating',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.type}</span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<LocationsOutOfRating_1 v0={<LocationsOutOfRatingStyles>
      <div className="counts">
        <div className="counts__item">
          Усього: <b>{counts?.total}</b>
        </div>
        {counts?.counts.map(el => <div className="counts__item">
            {el.value}: <b>{el.count}</b>
          </div>)}
      </div>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </LocationsOutOfRatingStyles>} />;
};