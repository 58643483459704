import React, { useEffect, useRef, useState } from 'react';
import { MassmailerStyles } from './MassmailerStyles';
import { useAppSelector } from '../../state';
import { TabMailKeys, tabsEdit } from './utils';
import { Helmet } from 'react-helmet-async';
import { CampaignTable } from './campaignTable/CampaignTable';
import { CampaignEmail, CampaignSms, CompanyFilter, CustomTabsMenu, Modal, SubHeader, Templaters, TransparentButton } from '../../components';
import { ApiEmailCampaigns, ApiEmailHistoryItem, ApiSmsCampaigns, massMailingApi, companyApi, ApiViberHistoryItem } from '../../api';
import { CompaniesMainInfo } from '../../entities';
import { Sms } from './sms';
import molData from './email/email.json';
import _ from 'lodash';
import { CustomSelect } from '../../components/molecules/customSelect/CustomSelect';
import { ApiCampaign } from '../../api/entities/ApiCampaigns';
export const Massmailer = () => {
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedTab, setSelectedTab] = useState(tabsEdit(interfaceLanguage)[0].key);
  const channels = [{
    channel: 'SMS'
  }, {
    channel: 'Viber'
  }, {
    channel: 'Email'
  }];
  const [selectedChanel, setSelectedChanel] = useState<{
    channel: 'SMS' | 'Viber' | 'Email';
  }>();
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [campaignsSms, setCampaignsSms] = useState<ApiSmsCampaigns[]>([]);
  const [campaignsViber, setCampaignsViber] = useState<ApiSmsCampaigns[]>([]);
  const [selectedCampaignSms, setSelectedCampaignSms] = useState<ApiSmsCampaigns>();
  const [campaignsEmail, setCampaignsEmail] = useState<ApiEmailCampaigns[]>([]);
  const [selectedCampaignEmail, setSelectedCampaignEmail] = useState<ApiEmailCampaigns>();
  const [addCampaign, setAddCampaign] = useState<boolean>(false);
  const [templater, setTemplater] = useState<boolean>(false);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const getCompanies = (data: CompaniesMainInfo[]) => {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      acc[`${item.id}`] = item;
      return acc;
    }, {} as {
      [key: string]: CompaniesMainInfo;
    });
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const id = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: id
      };
      setReQuery(!reQuery);
    }
  };
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, []);
  useEffect(() => {
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID && selectedChanel?.channel === 'SMS') {
      massMailingApi.massMailingSmsCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          setCampaignsSms(sortedData);
        }
      });
    }
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID && selectedChanel?.channel === 'Viber') {
      massMailingApi.massMailingViberCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          setCampaignsViber(sortedData);
        }
      });
    }
    if (selectedTab === TabMailKeys.campains && filterParamsRef.current.companyID) {
      setCampaignsSms([]);
    }
    if (selectedTab === TabMailKeys.campains && selectedChanel?.channel === 'Email') {
      massMailingApi.massMailingEmailCampaignList(+filterParamsRef.current.companyID).then(res => {
        const sortedData = _.orderBy(res.data.campaigns, ['createdAt'], ['desc']);
        if (res.statusCode === 200) {
          // @ts-ignore
          setCampaignsEmail(sortedData);
        }
      });
    }
  }, [selectedTab, filterParamsRef.current, addCampaign, templater, selectedChanel]);
  const handleDelete = async (id: number) => {
    await massMailingApi.deleteMassSmsMailingCampaign(id);
    setCampaignsSms(prevCamp => prevCamp.filter(e => e.id !== id));
  };
  return <MassmailerStyles>
      <Helmet>
        <title>Масові розсилки</title>
      </Helmet>
      <SubHeader strechChild title="Масові розсилки">
        <div className="massmailing-subheader">
          {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={params => {
          filterParamsRef.current = params;
        }} setReQuery={() => setReQuery(!reQuery)} />}
          {selectedTab === TabMailKeys.campains && <CustomSelect containerStyle={{
          marginTop: 0
        }} extraStyles={{
          maxWidth: '300px'
        }} name="channels" options={channels} placeholder="Канал комунікації" labelField="channel" selected={selectedChanel} onChange={value => {
          setSelectedChanel(value);
        }} />}
        </div>
      </SubHeader>
      <CustomTabsMenu tabs={tabsEdit(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {filterParamsRef.current.companyID && selectedTab === TabMailKeys.createCampain && <CampaignTable filterParams={filterParamsRef} setAddCampaign={() => setAddCampaign(true)} setTemplater={() => setTemplater(true)} companyID={+filterParamsRef.current.companyID} />}
      {selectedChanel && <div className="history-container">
          {selectedChanel?.channel === 'SMS' && selectedTab === TabMailKeys.campains && !!campaignsSms.length && campaignsSms.map(campaign => <CampaignSms handleDelete={handleDelete} selectedCampaign={selectedCampaignSms} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignSms} type="SMS" companyID={+filterParamsRef.current.companyID} />)}

          {selectedChanel?.channel === 'Viber' && selectedTab === TabMailKeys.campains && !!campaignsViber.length && campaignsViber.map(campaign => <CampaignSms handleDelete={handleDelete} selectedCampaign={selectedCampaignSms} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignSms} type="Viber" companyID={+filterParamsRef.current.companyID} />)}

          {selectedChanel?.channel === 'Email' && selectedTab === TabMailKeys.campains && !!campaignsEmail.length && campaignsEmail.map(campaign => <CampaignEmail selectedCampaign={selectedCampaignEmail} key={campaign.id} campaign={campaign} setSelectedCampaign={setSelectedCampaignEmail} type="Email" companyID={+filterParamsRef.current.companyID} />)}
        </div>}

      {addCampaign && <Modal title="Створення розсилки" onClose={() => setAddCampaign(false)}>
          <Sms filters={filterParamsRef.current} setAddCampaign={setAddCampaign} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
      {templater && <Modal title="Редактор шаблонів" onClose={() => setTemplater(false)}>
          <Templaters setTemplater={setTemplater} companyID={+filterParamsRef.current.companyID} />
        </Modal>}
    </MassmailerStyles>;
};