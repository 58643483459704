import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TAnalyticsFilter = {
  ascCategories?: string[];
  cnapTypes?: string[];
  cnapTypesRating?: string[];
  csiFieldIDs?: number[];
  csiPositiveAnswers?: string[];
  division?: string;
  fieldIDs?: number[];
  firstQuestion?: number;
  from?: string;
  idfs?: string[];
  isDiia?: boolean;
  negativeAnswers?: string[];
  npsFieldIDs?: number[];
  npsNegativeAnswers?: string[];
  npsPositiveAnswers?: string[];
  organTypes?: string[];
  positiveAnswers?: string[];
  regionLevel?: number;
  regions?: number[];
  relatedByOrgans?: number[];
  to?: string;
  year?: number;
  quarter?: number;
  groupsByDates?: string;
  groupsBy?: {
    dateParam?: string;
    fieldID?: number;
    metaKey?: string;
    reviewParam?: string;
  }[];
};

export interface AnalyticState {
  link: string;
  filters: TAnalyticsFilter;
}

const initialState: AnalyticState = {
  filters: {},
  link: '',
};

export const analyticSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {
    setSaveFilters: (state, action: PayloadAction<TAnalyticsFilter>) => ({
      ...state,
      filters: action.payload,
    }),
    setSaveLink: (state, action: PayloadAction<string>) => ({
      ...state,
      link: action.payload,
    }),
  },
  extraReducers: (builder) => {},
});

export const { actions: analyticActions, reducer: analyticReducer } = analyticSlice;
// Extract and export each action creator by name
export const { setSaveFilters, setSaveLink } = analyticActions;
