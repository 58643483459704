import styled from 'styled-components';

export const MassmailerStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .massmailing-subheader {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    padding-left: 20px;
  }

  .btns-containe {
    padding: 20px 0 0 20px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .history-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
  }

  .modalBody {
    background: #fff;
    min-width: 320px;
    transition: all 0.3s ease-in;
    max-height: 80vh;
  }
`;
