import styled from 'styled-components';
export const CampaignStyles = styled.div`
  width: 100%;
  padding: 20px;
  height: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  
  .controlsRow {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }

  .rating {
    height: calc(100% - 32px);
    position: relative;
    overflow: hidden;
  }
`;