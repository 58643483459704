import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiUser } from '../../api';
import { RegistrationStatus } from '../../constants/registrationStatuses';
import { loginUser, saveUserCredentials } from '../thunk/authThunk';
import { AuthUser } from '../../entities/AuthUser';
import { accessRequest, getUser } from '../thunk/accessesThunk';

export interface UserState {
  data: AuthUser | null;
  companies: number[];
  loggedIn: boolean;
  status: 'idle' | 'loading' | 'failed' | 'success';
  statusCode: number;
  message: string | null;
}

const initialState: UserState = {
  data: null,
  companies: [],
  loggedIn: false,
  status: 'idle',
  statusCode: 200,
  message: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUser: (state, action: PayloadAction<AuthUser | null>) => ({
      ...state,
      data: action.payload,
      loggedIn: false,
    }),
    setUser: (state, action: PayloadAction<AuthUser>) => ({
      ...state,
      data: action.payload,
    }),
    resetMessage: (state) => ({ ...state, message: null }),
    setCompanies: (state, action: PayloadAction<number[]>) => ({
      ...state,
      companies: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = action.payload.status === RegistrationStatus.assigned;
        state.data = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.loggedIn = false;
      })
      // saveUserCredentials
      .addCase(saveUserCredentials.pending, (state, action) => {
        state.status = 'loading';
        state.loggedIn = false;
      })
      .addCase(saveUserCredentials.rejected, (state, action) => {
        state.status = 'idle';
        state.loggedIn = false;
      })
      .addCase(saveUserCredentials.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = action.payload.status === RegistrationStatus.assigned;
        state.data = action.payload;
        // state.registrationStatus = action.payload.status as RegistrationStatus;
      })
      .addCase(accessRequest.fulfilled, (state, action) => {
        state.status = 'success';
        state.loggedIn = action.payload.status === RegistrationStatus.assigned;
        state.data = action.payload;
        // state.registrationStatus = action.payload.status as RegistrationStatus;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.loggedIn = action.payload.status === RegistrationStatus.assigned;
        state.data = action.payload;
        // state.registrationStatus = action.payload.status as RegistrationStatus;
      });
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
// Extract and export each action creator by name
export const {
  changeUser, setUser, resetMessage, setCompanies,
} = userActions;
