import { useEffect, useMemo } from 'react';
import { useAppSelector } from '../state';
import { TPermissionValue } from '../entities';

export const usePermissionView = () => {
  const user = useAppSelector((state) => state.user.data);
  const userPermissions = user?.permissions;

  const hasAllAccesses = useMemo(
    () => !!userPermissions?.find((permission) => permission.resource === '/*'),
    [userPermissions],
  );

  const hasAnyAccess = (access: string) => {
    const hasAccessPermissions = userPermissions?.find((el) => el.resource.includes(access));

    return {
      edit: [1, 4, 8].some((num) => hasAccessPermissions?.permissions.includes(num as TPermissionValue)),
      view: !!hasAccessPermissions,
    };
  };

  return { hasAllAccesses, hasAnyAccess };
};
