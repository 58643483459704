import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnalyticStyles } from './styles';
import { Helmet } from 'react-helmet-async';
import { CompaniesMainInfo } from '../../entities';
import { companyApi, exportAnalyticsApi } from '../../api';
import { AnalyticsFilters, AnalyticsStartPage, CompanyFilter, ComplexGeneralDashboard, ComplexRatingDashboard, CsiDashboard, CsiDetailedDashboard, CsiRegionRatingsDashboard, CustomSelect, DataForDownloadDashboard, DissatisfactionDashboard, DynamicDashboard, InformationRespondentsDashboard, LocationsOutOfRating, LocationsWithoutReviews, NpsCsiSatisfactionDashboard, NpsDetailedDashboard, QRPublishingDashboard, QRPublishingNodesDashboard, QRPublishingPieDashboard, RatingByPopulationDashboard, RatingByReviewsDashboard, RegionReviewsByStatusesDashboard, ReviewsByStatusesDashboard, SubHeader, TransparentButton } from '../../components';
import { setSidebarFullWidth, useAppDispatch, useAppSelector } from '../../state';
import { groups, TabKeys, tabs, TGroup } from './utils';
import { AnalyticFilterIcon, FullScreen, ListRegime, SaveDashboard, WrapRegime } from '../../assets';
import download from 'downloadjs';
export const Analytic = React.memo(() => {
  const dispatch = useAppDispatch();
  const {
    link,
    filters: savedFilters
  } = useAppSelector(state => state.analytic);
  const [companies, setCompanies] = useState<{
    [key: string]: CompaniesMainInfo;
  }>();
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [filters, setFilters] = useState<boolean>(false);
  const [regime, setRegime] = useState<string>('wrap');
  const [selectedTab, setSelectedTab] = useState<string>('');
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0
  });
  const [group, setGroup] = useState<TGroup>(groups[0]);
  const filterParamsRef = useRef<{
    [key: string]: number | string | string[] | number[] | boolean;
  }>({});
  const blockRef = useRef(null);
  function getCompanies(data: CompaniesMainInfo[]) {
    const apiCompanies: {
      [key: string]: CompaniesMainInfo;
    } = data.reduce((acc, item) => {
      acc[`${item.id}`] = item;
      return acc;
    }, {} as {
      [key: string]: CompaniesMainInfo;
    });
    if (Object.keys(apiCompanies).length > 1) {
      setCompanies(apiCompanies);
    } else {
      const compID = Object.values(apiCompanies)[0].id;
      filterParamsRef.current = {
        companyID: compID
      };
      setReQuery(!reQuery);
    }
  }
  const handleRefreshFilter = () => {
    filterParamsRef.current = {
      companyID: filterParamsRef.current.companyID
    };
    setReQuery(!reQuery);
  };
  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  const toggleFilters = () => {
    setFilters(!filters);
  };
  const handleDownload = useCallback(() => {
    const {
      companyID,
      ...restFilterParams
    } = filterParamsRef.current;
    exportAnalyticsApi.getDashboardsFile(+companyID, savedFilters, link).then(res => {
      if (res.statusCode === 201) {
        const filename = `${selectedTab}.xlsx`;
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        download(res.data as Blob, filename, mimeType);
      }
    });
  }, [reQuery, selectedTab, savedFilters, link]);
  useEffect(() => {
    companyApi.getCompanies().then(res => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
        dispatch(setSidebarFullWidth(false));
      }
    });
  }, []);
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      }
    });
    if (blockRef.current) {
      observer.observe(blockRef.current);
    }
    return () => {
      if (blockRef.current) {
        observer.unobserve(blockRef.current);
      }
    };
  }, [reQuery, fullScreen]);
  return <AnalyticStyles className="analytics">
      <Helmet>
        <title>Аналітика</title>
      </Helmet>
      <SubHeader strechChild title="Аналітика">
        <div className="analytics-subheader">
          <div className="analytics-select">
            {companies && <CompanyFilter filterParams={filterParamsRef.current} companies={companies} setFilterParams={params => {
            filterParamsRef.current = params;
          }} setReQuery={() => setReQuery(!reQuery)} />}
            <CustomSelect options={groups} selected={group} valueField="key" labelField="name" name="group" onChange={(value: any) => {
            setGroup(value);
          }} extraStyles={{
            maxWidth: '300px'
          }} />
          </div>
          <div className="analytics-btns">
            {selectedTab !== '' && <>
                <TransparentButton text="Повернутися до меню" handleClick={() => {
              setSelectedTab('');
              setFilters(false);
            }} filled />
                <TransparentButton text="Очистити фільтри" handleClick={handleRefreshFilter} extraStyles={{
              backgroundColor: '#767676',
              border: 'none'
            }} extraButtonTextStyles={{
              color: '#ffffff'
            }} />
              </>}
          </div>
        </div>
      </SubHeader>
      <div className={`analytics-wrapper ${fullScreen ? 'full-screen' : ''} ${selectedTab ? 'active-dashboard' : ''}`}>
        {filterParamsRef.current.companyID && <div className="analytics-header">
            <h2 className={`${selectedTab ? 'centered' : ''}`}>
              {selectedTab ? tabs.find(tab => tab.key === selectedTab)?.text! : group.description}
            </h2>
            <div className="analytics-actions">
              {!selectedTab && <button className={`analytics-regime ${regime === 'wrap' ? 'active' : ''}`} onClick={() => setRegime('wrap')} aria-label="Таблиця" type="button">
                  <WrapRegime height={20} width={20} />
                </button>}

              {!selectedTab && <button className={`analytics-regime ${regime === 'list' ? 'active' : ''}`} onClick={() => setRegime('list')} aria-label="Таблиця" type="button">
                  <ListRegime height={20} width={20} />
                </button>}

              <button className={`analytics-fullscreen ${fullScreen ? 'active' : ''}`} onClick={toggleFullScreen} aria-label="Повноекранний режим" type="button">
                <FullScreen height={20} width={20} />
              </button>

              {!filters && selectedTab && <button className="analytics-filters" onClick={toggleFilters} aria-label="Обрати фільтри" type="button">
                  <AnalyticFilterIcon height={20} width={20} />
                </button>}

              {selectedTab && <button className="analytics-save" onClick={handleDownload} aria-label="Завнтажити звіт" type="button">
                  <SaveDashboard height={20} width={20} />
                </button>}
            </div>
          </div>}
        {filterParamsRef.current.companyID && <AnalyticsFilters companyId={+filterParamsRef.current.companyID} filterParams={filterParamsRef} reQuery={reQuery} filters={filters} setFilters={setFilters} setReQuery={setReQuery} setSelectedTab={setSelectedTab} selectedTab={selectedTab} handleRefreshFilter={handleRefreshFilter} />}
        {filterParamsRef.current.companyID && <div className="analytics-charts" ref={blockRef}>
            {selectedTab === TabKeys.csi && <CsiDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.csiDetailed && <CsiDetailedDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.npsCsiSatisfaction && <NpsCsiSatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.npsDetailed && <NpsDetailedDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.informationRespondents && <InformationRespondentsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.csiRegionRatings && <CsiRegionRatingsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.ratingPopulationOne && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="I категорія (100 тис. осіб і більше)" />}
            {selectedTab === TabKeys.ratingPopulationTwo && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="II категорія (від 50 до 100 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationThree && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="III категорія (від 20 до 50 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationFour && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="IV категорія (від 10 до 20 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationFive && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="V категорія (від 5 до 10 тис.осіб)" />}
            {selectedTab === TabKeys.ratingPopulationSix && <RatingByPopulationDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="VI категорія (менше 5 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsOne && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="I категорія (100 тис. осіб і більше)" />}
            {selectedTab === TabKeys.ratingReviewsTwo && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="II категорія (від 50 до 100 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsThree && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="III категорія (від 20 до 50 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsFour && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="IV категорія (від 10 до 20 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsFive && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="V категорія (від 5 до 10 тис.осіб)" />}
            {selectedTab === TabKeys.ratingReviewsSix && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascCategories="V категорія (від 5 до 10 тис.осіб)" />}
            {selectedTab === TabKeys.ratingVrmReviews && <RatingByReviewsDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} ascTypes="ВРМ" />}
            {selectedTab === TabKeys.complexRating && <ComplexRatingDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationOutOfRating && <LocationsOutOfRating filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationWithoutReviews && <LocationsWithoutReviews filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.locationDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="location-dissatisfaction" divisionPie="location-dissatisfaction-pie" />}
            {selectedTab === TabKeys.generalProcessDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="general-process-dissatisfaction" divisionPie="general-process-dissatisfaction-pie" />}
            {selectedTab === TabKeys.staffDissatisfaction && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="staff-dissatisfaction" divisionPie="staff-dissatisfaction-pie" />}
            {selectedTab === TabKeys.servicesList && <DissatisfactionDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} divisionData="services-list-dissatisfaction" divisionPie="services-list-dissatisfaction-pie" />}
            {selectedTab === TabKeys.complexGeneral && <ComplexGeneralDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} group={group} />}
            {selectedTab === TabKeys.qrPublishing && <QRPublishingDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {/* {selectedTab === TabKeys.qrPublishingNodes && (
              <QRPublishingNodesDashboard
                filterParams={filterParamsRef.current}
                reQuery={reQuery}
                dimensions={dimensions}
                setReQuery={setReQuery}
              />
             )} */}
            {selectedTab === TabKeys.qrPublishingPie && <QRPublishingPieDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.reviewsByStatuses && <ReviewsByStatusesDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.regionReviewsByStatuses && <RegionReviewsByStatusesDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.dynamic && <DynamicDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {selectedTab === TabKeys.dataForDownload && <DataForDownloadDashboard filterParams={filterParamsRef.current} reQuery={reQuery} dimensions={dimensions} setReQuery={setReQuery} />}
            {!selectedTab && <AnalyticsStartPage setSelectedTab={setSelectedTab} tabs={tabs} group={group} regime={regime} />}
          </div>}
      </div>
    </AnalyticStyles>;
});