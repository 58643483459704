import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { InputFieldStyles } from './styles';
import { ErrorMessage, FormikContextType, useFormikContext } from 'formik';
import { EyeIcon } from '../../../../assets';
import { FormErrorMessage } from '../../../atoms';
import PhoneInput from 'react-phone-input-2';
interface FormValues {
  [key: string]: any;
}
interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder: string;
  onChange: (key: string, value: string) => void;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  extraInputStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  autocomplete?: boolean;
}
export const InputField = React.memo(({
  name,
  placeholder,
  label,
  error,
  showError = true,
  value,
  min,
  max,
  onChange,
  onKeyUp,
  extraBlockStyles,
  extraInputStyles,
  disabled,
  type,
  required,
  autocomplete
}: InputFieldProps) => {
  const {
    touched,
    errors
  } = useFormikContext<FormValues>() as FormikContextType<FormValues>;
  const [hide, setHide] = useState<boolean>(type === 'password');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const isError = useMemo(() => !!error || errors[name]?.length, [error, errors]);
  useEffect(() => {
    if (type === 'tel') setIsFocused(true);
  }, []);
  return <InputFieldStyles disabled={disabled} style={extraBlockStyles}>
        <label htmlFor={name}>
          <span className={`${isFocused || value?.toString().length ? 'focused' : ''} ${isError ? 'error' : ''}`}>
            {placeholder}
          </span>
          {!(type === 'tel') && <input id={name} type={(type === 'password' ? hide ? 'password' : 'text' : type) || 'text'} min={type === 'number' ? min : undefined} max={type === 'number' ? max : undefined} value={value} onChange={event => onChange(name, event.target.value)} onKeyUp={onKeyUp} disabled={disabled} className={`
              ${disabled ? 'disabled' : ' '} 
              ${isError ? 'error' : ' '} 
              ${type || ' '}
              `} style={extraInputStyles} autoComplete={autocomplete ? 'on' : 'new-password'} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} />}
          {type === 'tel' && <PhoneInput specialLabel="" country="ua" countryCodeEditable={false} value={value?.toString()} onChange={event => onChange(name, `+${event}`)} />}
          {showError && <div className="formErrorContainer">
              {error && <span>{error}</span>}
              {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
            </div>}
          {type === 'password' && <button type="button" onClick={() => setHide(!hide)}>
              <EyeIcon dynamicClass={hide} />
            </button>}
        </label>
      </InputFieldStyles>;
});