import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { QRPublishingDashboardStyles } from './styles';
import { analyticsApi, ApiQRPublishing, TPostQRPublishing } from '../../../../api';
import { Table } from '../../../organisms';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
import { uk
// eslint-disable-next-line import/no-duplicates
} from 'date-fns/locale';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TQRPublishing = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const QRPublishingDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "QRPublishingDashboard_1",
  portals: ["v0"]
});
export const QRPublishingDashboard: FC<TQRPublishing> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiQRPublishing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostQRPublishing = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getQRPublishing(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      setData(prev => [...prev, ...res.data]);
      dataCount.current = res.count ? res.count : 0;
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/qr-publishing-pie/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    if (data.length) {
      setTableHeaders([{
        Header: 'ID Відгуку',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.id}</span>
            </div>
      }, {
        Header: 'Дата відгука',
        accessor: 'createdAt',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{format(new Date(data.row.original.createdAt), 'dd MMMM yyyy', {
              locale: uk
            })}</span>
            </div>
      }, {
        Header: 'Місяць',
        accessor: 'formattedDate',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.formattedDate.split('-').join('.')}</span>
            </div>
      }, {
        Header: 'IDF',
        accessor: 'idf',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.idf}</span>
            </div>
      }, {
        Header: 'Область',
        accessor: 'state',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.state}</span>
            </div>
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: () => <div className="dashboard-name">
              <span>Назва центру</span>
            </div>,
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.name}</span>
            </div>
      }, {
        Header: 'Тип ЦНАП',
        accessor: 'centerType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.centerType}</span>
            </div>
      }, {
        Header: 'Тип органу що утворив',
        accessor: 'createdCenterType',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.createdCenterType}</span>
            </div>
      }, {
        Header: 'Назва органу, що утворив центр',
        accessor: 'region',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.region}</span>
            </div>
      }, ...data[0].answers.map((el, index) => ({
        Header: el.key,
        accessor: `answer_${el.id}`,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <span style={{
            color: '#000'
          }}>{data.row.original.answers[index].value}</span>
            </div>
      })), {
        Header: 'Посилання на відгук',
        accessor: 'url',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <div className="rating-col">
              <a href={data.row.original.url} style={{
            color: '#000'
          }}>Відкрити відгук</a>
            </div>
      }]);
    }
  }, [data]);
  return /*@million jsx-skip*/<QRPublishingDashboard_1 v0={<QRPublishingDashboardStyles>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </QRPublishingDashboardStyles>} />;
};