import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CsiRegionRatingsDashboardStyles } from './styles';
import { HorizontalBar } from '../../analyticsCharts';
import { analyticsApi, ApiCsiRegionRating, TPostDashboardData } from '../../../../api';
import { DataItem } from '../../../../pages';
import { Loader } from '../../../atoms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type PieData = {
  label: string;
  data: DataItem[];
};
type TCsiRegionRatingsDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#CFC4FF', '#A7C4E9', '#F4EF67', '#e45574'];
export const CsiRegionRatingsDashboard: FC<TCsiRegionRatingsDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [ratingData, setRatingData] = useState<ApiCsiRegionRating | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const maxReviewsCount = useMemo(() => ratingData ? Math.max(...ratingData.reviews.map(el => el.views)) : 0, [ratingData]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostDashboardData = {
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getCsiRegionRatings(+companyID, filteredData).then(res => {
      setRatingData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/regions-csi-ratings/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      isDiia: isDiia as boolean
    }));
  }, [reQuery]);
  return <CsiRegionRatingsDashboardStyles>
      {loading && <Loader />}

      {!loading && <div className="bars">
          <div className="bars-item">
            <h4>CSI</h4>
            <div className="chart">
              {!!ratingData?.csi.length && ratingData.csi.sort((a, b) => b.count - a.count).map(el => <div key={`${el.label}-csi`} className="chart-item">
                    <h6>{el.label}</h6>
                    <div className="chart-fill">
                      <span>{el.count}%</span>
                      <div style={{
                background: colors[0],
                width: `${el.count}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
          <div className="bars-item">
            <h4>NPS</h4>
            <div className="chart">
              {!!ratingData?.nps.length && ratingData.nps.sort((a, b) => b.count - a.count).map(el => <div key={`${el.label}-nps`} className="chart-item">
                    <h6>{el.label}</h6>
                    <div className="chart-fill">
                      <span>{el.count}%</span>
                      <div style={{
                background: el.count >= 0 ? colors[1] : colors[3],
                width: `${Math.abs(el.count)}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
          <div className="bars-item">
            <h4>Кількість відгуків</h4>
            <div className="chart">
              {!!ratingData?.reviews.length && ratingData.reviews.sort((a, b) => b.views - a.views).map(el => <div key={`${el.label}-csi`} className="chart-item">
                    <h6>{el.label}</h6>
                    <div className="chart-fill">
                      <span>{el.views}</span>
                      <div style={{
                background: colors[2],
                width: `${el.views / maxReviewsCount * 100}%`
              }} />
                    </div>
                  </div>)}
            </div>
          </div>
        </div>}
    </CsiRegionRatingsDashboardStyles>;
};