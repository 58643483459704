/* eslint-disable class-methods-use-this */
import baseApi from '../baseApi';
import { ApiAuthResponse, ApiResponse, ApiUser } from '../entities';
import IAccessesApi, { AccessesParams } from './IAccessesApi';

export type AccessRequest = {
  companyID: number;
  createdAt: string;
  id: number;
  managerID: number;
  nodesIDs: number[];
  processedBy: number;
  status: 'pending' | 'rejected' | 'approved';
  updatedAt: string;
  workerID: number;
};

class AccessesApi implements IAccessesApi {
  async accessReviewList({
    isManager,
    status,
    limit,
    offset,
    nodesIDs,
    workerIDs,
    managerIDs,
  }: AccessesParams): Promise<
    ApiResponse<{ requests: AccessRequest[]; count: number }>
  > {
    const response = await baseApi.getData(
      `/view-access?limit=${limit}&offset=${offset}&isManager=${isManager}${
        status?.length ? `&statuses=${status.join('&statuses=')}` : ''
      }${nodesIDs?.length ? `&nodesIDs=${nodesIDs.join('&nodesIDs=')}` : ''}${
        workerIDs?.length ? `&workersIDs=${workerIDs.join('&workersIDs=')}` : ''
      }${
        managerIDs?.length
          ? `&managersIDs=${managerIDs.join('&managersIDs=')}`
          : ''
      }`,
    );
    return {
      data: response.data,
      statusCode: 200,
    };
  }

  async accessReviewListPost({
    isManager,
    status,
    limit,
    offset,
    nodesIDs,
    workerIDs,
    managerIDs,
    compayIDs,
  }: AccessesParams): Promise<
    ApiResponse<{ requests: AccessRequest[]; count: number }>
  > {
    const response = await baseApi.postData('/view-access/list', {
      companiesIDs: compayIDs,
      isManager,
      limit,
      managersIDs: managerIDs,
      nodesIDs,
      offset,
      order: ['createdAt:asc'],
      statuses: status,
      workersIDs: workerIDs,
    });
    return {
      data: response.data,
      statusCode: 200,
    };
  }

  async createAccessReview({
    userID,
    company,
    organization,
    userType,
    companyID,
    nodesIDs,
  }: {
    company: string;
    organization: string;
    userType: string;
    companyID: number;
    nodesIDs: number[];
    userID: number;
  }): Promise<ApiResponse<null>> {
    const response = await baseApi.postData('/view-access', {
      company,
      organization,
      userType,
      companyID,
      nodesIDs,
    });
    return {
      data: response.data.requests,
      statusCode: 200,
    };
  }

  async sendPosition({
    userID,
    position,
  }: {
    position: string;
    userID: number; // посада
  }): Promise<ApiResponse<null>> {
    const response = await baseApi.putData(`/users/${userID}/position`, {
      position,
    });
    return {
      data: response.data.requests,
      statusCode: 200,
    };
  }

  async rejectAccessRequest(request_id: number): Promise<ApiResponse<null>> {
    const response = await baseApi.postData(
      `/view-access/${request_id}/reject`,
    );
    return { statusCode: 200, data: null };
  }

  async approveAccessRequest(request_id: number): Promise<ApiResponse<null>> {
    const response = await baseApi.postData(
      `/view-access/${request_id}/approve`,
    );
    return { statusCode: 200, data: null };
  }
}

export const accessesApi = new AccessesApi();
