import React, { useState, useRef, CSSProperties, useEffect } from 'react';
import { FilterSelectInputStyles } from './FilterSelectInputStyles';
import { translations } from '../../../../i18n';
import { ArrowDownIcon, TableFilterIcon } from '../../../../assets';
import { useAppSelector } from '../../../../state';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { IReviewStatus } from '../../../../entities/IReviewStatus';
import { CalendarFilter, NodeFilter, SourceFilter, StatusFilter, NetworkFilter, ScoreFilter, AttachmentFilter, SentimentFilter } from '..';
import { SubmittedFilter } from '../submittedFilter';
import { CategoryFilter } from '../categoryFilter';
import { AnswersFilter } from '../answersFilter';
import { initialGenderValues, initialScoreValues, initialServicesTypeValues, initialTypeValues, initialYearValues, initialAgeValues, initialCnapValues } from './initialValues';
import { DropdownFilter } from '../../dropDownFilter';
import { TQuestion } from '../scoreFilter/TScoreFilter';
import { map } from 'lodash';
import { issuesApi, tagsApi } from '../../../../api';
type TColumnName = 'attachment' | 'name' | 'nodes' | 'categories' | 'statuses' | 'sources' | 'keyAnswer' | 'contacts' | 'attachmentTypes' | 'sentiment' | 'createdAt' | 'opened' | 'submitted' | 'sendDate'
// aditional filters
| 'years' | 'types' | 'idf' | 'communication_channel' | 'age' | 'gender' | 'comments' | 'tag' | 'status' | 'answers' | 'services' | 'cnap' | 'statusIDs' | 'region' | 'visited_at' | 'service_name' | 'service_result' | 'service_status' | 'service_intime' | 'phone' | 'state';
interface IFilterSelectInput {
  resizable?: boolean;
  search?: boolean;
  type?: 'checkbox' | 'radio' | 'switch' | 'dataInterval';
  initiaFilterlValues?: {
    id: string;
    value: boolean;
    name: string;
    ico?: any;
  }[];
  columnName: TColumnName | string;
  filterName?: string;
  title?: string;
  filterParams: {
    [key: string]: number | string | any;
  };
  setReQuery: () => void;
  setApply: (params: {
    [key: string]: number | string;
  }) => void;
  setFilterParams: (params: {
    [key: string]: number | string;
  }) => void;
  status?: IReviewStatus[];
  containerExtraStyles?: string;
  extraFilterFormStyles?: string;
  optionsExtraStyles?: string;
  options?: any;
  filtersOpenerStyles?: CSSProperties;
  icon?: boolean;
  filterQuestions?: TQuestion[];
  resetFilterQuestions?: () => void;
  extraStyles?: CSSProperties;
  autoSelectChildren?: boolean;
}
export const FilterSelectInput = React.memo(({
  filterName,
  title,
  filterParams,
  setReQuery,
  setApply,
  setFilterParams,
  status,
  containerExtraStyles,
  extraFilterFormStyles,
  optionsExtraStyles,
  options,
  icon,
  filterQuestions,
  resetFilterQuestions,
  filtersOpenerStyles,
  extraStyles,
  // new universal filter types
  resizable,
  search,
  type,
  initiaFilterlValues,
  columnName,
  // needs to be chaged
  autoSelectChildren
}: IFilterSelectInput) => {
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const filtersRef = useRef<any>();
  const mapAnswers = (questions: TQuestion[]) => {
    const questionAnswers: {
      [key: number]: any[];
    } = {};
    questions?.forEach(question => {
      const selectedAnswers = question.answers?.filter(answer => !!answer.value).map(e => e.id);
      if (selectedAnswers?.length) {
        questionAnswers[question.id] = selectedAnswers;
      }
    });
    return questionAnswers;
  };
  const isFilterQuestions = !!Object.keys(mapAnswers(filterQuestions!)).length;
  useClickOutside(filtersRef, () => setIsFilterOpened(false));
  const isArrayNotEmpty = Array.isArray(filterParams[filterName ?? columnName]) ? filterParams[filterName ?? columnName].length > 0 : !!filterParams[filterName ?? columnName]?.toString();
  const createdAtFilter = columnName === 'createdAt' && filterParams.from && filterParams.to;
  const visitedAtFilter = columnName === 'visited_at' && filterParams.from && filterParams.to;
  const sendDateFilter = columnName === 'sendDate' && filterParams.from && filterParams.to;
  const isScore = columnName === 'keyAnswer' && filterParams.scores;
  const shouldRenderIcon = isArrayNotEmpty || createdAtFilter || visitedAtFilter || isScore || sendDateFilter || isFilterQuestions;
  const [initialTags, setInitialTags] = useState<{
    id: string;
    value: boolean;
    name: string;
  }[]>();
  const [initialStatusIds, setInitialStatusIds] = useState<{
    id: string;
    value: boolean;
    name: string;
  }[]>();
  useEffect(() => {
    if (columnName === 'tag') {
      tagsApi.getCompanyTags(filterParams.companyID).then(e => {
        setInitialTags(e.data?.map(tag => ({
          name: tag.name,
          value: filterParams.tags?.includes(tag.name),
          id: `${tag.name}`
        })));
      });
    }
  }, []);
  useEffect(() => {
    if (columnName === 'statusIDs') {
      issuesApi.getTicketStatusesByCompany(filterParams.companyID).then(e => {
        setInitialStatusIds(e.data?.map(status => ({
          name: status.name,
          value: filterParams.statusIDs?.includes(status.id),
          id: status.id!.toString()
        })));
      });
    }
  }, []);
  return <FilterSelectInputStyles style={extraStyles} ref={filtersRef}>
        <div className="filterRow">
          <div style={filtersOpenerStyles} className={`filtersOpener ${isFilterOpened ? 'filtersOpener-active' : ''}`} onClick={() => setIsFilterOpened(!isFilterOpened)}>
            <span className="filterTitle">{title}</span>
            <ArrowDownIcon />
          </div>
          {shouldRenderIcon && <TableFilterIcon width={15} height={15} />}
        </div>
        {isFilterOpened && <div>
            {type === 'checkbox' && initiaFilterlValues && <DropdownFilter resizable={resizable} search={search} name={columnName} initialValues={initiaFilterlValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'statusIDs' && initialStatusIds && <DropdownFilter resizable={resizable} search={search} name="statusIDs" initialValues={initialStatusIds} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'tag' && initialTags && <DropdownFilter name="tags" initialValues={initialTags} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'services' && <DropdownFilter name="services" initialValues={initialServicesTypeValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'nodes' && <NodeFilter autoSelectChildren={autoSelectChildren} filterName={filterName} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} resizable containerExtraStyles={containerExtraStyles} extraFilterFormStyles={extraFilterFormStyles} optionsExtraStyles={optionsExtraStyles} />}
            {columnName === 'categories' && <CategoryFilter filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} resizable containerExtraStyles={containerExtraStyles} extraFilterFormStyles={extraFilterFormStyles} optionsExtraStyles={optionsExtraStyles} />}
            {columnName === 'statuses' && <StatusFilter filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} status={status} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'sources' && <SourceFilter filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'keyAnswer' && <ScoreFilter
      // name="scores"
      initialValues={{
        scores: initialScoreValues
      }} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'years' && <DropdownFilter name="years" initialValues={initialYearValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'age' && <DropdownFilter name="age" initialValues={initialAgeValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'gender' && <DropdownFilter name="genders" initialValues={initialGenderValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'types' && <DropdownFilter name="types" initialValues={initialTypeValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'cnap' && <DropdownFilter name="cnap" initialValues={initialCnapValues} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'answers' && <AnswersFilter
      // initialValues={{ questions: initialScoreValues }}
      filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} filterQuestions={filterQuestions!} resetFilterQuestions={resetFilterQuestions!} />}
            {columnName === 'contacts' && <NetworkFilter filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'attachmentTypes' && <AttachmentFilter filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'sentiment' && <SentimentFilter filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'createdAt' && <div style={{
        width: '100%'
      }}>
                <CalendarFilter setFilterParams={setFilterParams} filterParams={filterParams} setReQuery={setReQuery} onClose={() => setIsFilterOpened(false)} setApply={setApply} />
              </div>}
            {columnName === 'opened' && <SubmittedFilter columnName={columnName} options={options[columnName]} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} />}
            {columnName === 'submitted' && <SubmittedFilter columnName={columnName} filterParams={filterParams} setFilterParams={setFilterParams} setReQuery={setReQuery} setApply={setApply} onClose={() => setIsFilterOpened(false)} options={options[columnName]} />}
            {columnName === 'sendDate' && <div style={{
        width: '100%'
      }}>
                <CalendarFilter setFilterParams={setFilterParams} filterParams={filterParams} setReQuery={setReQuery} onClose={() => setIsFilterOpened(false)} setApply={setApply} />
              </div>}
          </div>}
      </FilterSelectInputStyles>;
});