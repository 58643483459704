import React, { Dispatch, FC, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { QRPublishingPieDashboardStyles } from './styles';
import { analyticsApi, ApiQRPublishingNodes, ApiQRPublishingPie, TPostQRPublishing } from '../../../../api';
import { DataItem } from '../../../../pages';
import { PieChart } from '../../analyticsCharts';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
import { Loader } from '../../../atoms';
type TQRPublishing = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const colors: string[] = ['#F4EF67', '#F2C3DB', '#6EAE93', '#BB6685', '#BCB9D0', '#CFC4FF', '#A7C4E9', '#BA806E', '#FF5C53', '#FA7B4C', '#C3DBDB'];
const classNames = ['three', 'four', 'five'];
type barData = {};
export const QRPublishingPieDashboard: FC<TQRPublishing> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiQRPublishingPie | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [qrData, setQRData] = useState<ApiQRPublishingNodes[]>([]);
  const maxNodes = useMemo(() => Math.max(...qrData.map(el => el.nodesCount)), [qrData]);
  const maxReviews = useMemo(() => Math.max(...qrData.map(el => el.reviewCount)), [qrData]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostQRPublishing = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getQRPublishingPie(+companyID, filteredData).then(res => {
      setData(res.data);
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/qr-publishing-graph/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    }));
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostQRPublishing = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean
    };
    setLoading(true);
    analyticsApi.getQRPublishingNodes(+companyID, filteredData).then(res => {
      setQRData(res.data);
      setLoading(false);
    });
  }, [reQuery]);
  if (loading) {
    return <QRPublishingPieDashboardStyles>
        <Loader />
      </QRPublishingPieDashboardStyles>;
  }
  return <QRPublishingPieDashboardStyles>
      <div className="charts">
        <div className="charts-item charts-one">
          <h4>Підключено точок ЦНАП до платформи:</h4>
          <div className="counts">
            <h2>{data?.cnapNodesCount}</h2>
          </div>
        </div>
        <div className="charts-item charts-two">
          <h4>Всього надійшло заповнених анкет:</h4>
          <div className="counts">
            <h2>{data?.reviewsCount}</h2>
          </div>
        </div>
        {data?.pieItems.map((el, index) => {
        const pies: DataItem[] = el.answers.map((e, i) => ({
          label: e.key,
          value: e.percentage,
          fill: colors[i],
          count: e.count
        }));
        return <div key={el.key} className="charts-item charts-three">
              <h4>{el.key}</h4>
              <div className="legend">
                {pies.map((e, i) => <div className="legend-item">
                    <div style={{
                background: colors[i]
              }} />
                    <p>{e.label}</p>
                  </div>)}
              </div>
              <div className="pies">
                <PieChart width={dimensions.width / 3} height={dimensions.height / 3} data={pies} chartLegend />
              </div>
            </div>;
      })}
        <div className="charts-item charts-four">
          <h4>Стан розміщення QR-кодів з посиланням на опитування в ЦНАП в розрізі регіонів</h4>
          <div className="legend">
            <div className="legend-item">
              <div style={{
              background: '#CFC4FF'
            }} />
              <p>Підключено точок ЦНАП</p>
            </div>
            <div className="legend-item">
              <div style={{
              background: '#A7C4E9'
            }} />
              <p>Отримано заповнених анкет</p>
            </div>
          </div>
          <div className="bars">
            {qrData.filter(item => item.state).map(el => <div className="bars-item" style={{
            height: `${100 / (qrData.length - 1)}%`
          }} key={el.state}>
                <div className="bars-fill bars-left">
                  <span>{el.nodesCount}</span>
                  <div style={{
                background: '#CFC4FF',
                width: `${el.nodesCount / maxNodes * 100}%`
              }} />
                </div>
                <h5>{el.state}</h5>
                <div className="bars-fill bars-right">
                  <span>{el.reviewCount}</span>
                  <div style={{
                background: '#A7C4E9',
                width: `${el.reviewCount / maxReviews * 100}%`
              }} />
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </QRPublishingPieDashboardStyles>;
};