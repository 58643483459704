import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { ReviewsByStatusesDashboardStyles } from './styles';
import { analyticsApi, ApiReviewByStatuses, ApiReviewByStatusesTotal, TPostReviewsByStatuses } from '../../../../api';
import { Table } from '../../../organisms';
import { setSaveFilters, setSaveLink, useAppDispatch } from '../../../../state';
type TReviewsByStatusesDashboard = {
  filterParams: {
    [key: string]: number | string | string[] | number[] | boolean;
  };
  setReQuery: Dispatch<SetStateAction<boolean>>;
  reQuery: boolean;
  dimensions: {
    width: number;
    height: number;
  };
};
const vocab: {
  [key: string]: string;
} = {
  cantSolve: 'Не може бути вирішено',
  closedAutoNoReply: 'Закрито автоматично завідсутності відповіді від респондента',
  expectingReply: 'Чекаємо на відповідь респондента',
  inProgress: 'В проботі',
  noSolutionRequired: 'Вирішення не потребує',
  notSolved: 'Не вирішено',
  notViewed: 'Не переглянуто',
  overdue: 'Прострочено',
  reopened: 'Перевідкрито',
  solved: 'Вирішено',
  total: 'Усього',
  viewed: 'Переглянуто'
};
const ReviewsByStatusesDashboard_1 = _compiledBlock(_props => _props.v0, {
  name: "ReviewsByStatusesDashboard_1",
  portals: ["v0"]
});
export const ReviewsByStatusesDashboard: FC<TReviewsByStatusesDashboard> = ({
  filterParams,
  setReQuery,
  reQuery,
  dimensions
}) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<ApiReviewByStatuses[]>([]);
  const [counts, setCounts] = useState<ApiReviewByStatusesTotal | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  const [pageNumber, setPageNumber] = useState(1);
  const dataCount = useRef<number>(0);
  useEffect(() => {
    if (!loading) {
      setData([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  useEffect(() => {
    const {
      companyID,
      from,
      to,
      regions,
      relatedByOrgans,
      idfs,
      organTypes,
      cnapTypes,
      isDiia,
      ...restFilterParams
    } = filterParams;
    const filteredData: TPostReviewsByStatuses = {
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    };
    setLoading(true);
    analyticsApi.getReviewsByStatuses(+companyID, filteredData, (pageNumber ? +pageNumber - 1 : 0) * 10, 10).then(res => {
      setData(prev => [...prev, ...res.data.data]);
      setCounts(res.data.totalCount);
      dataCount.current = res.count ? res.count : 0;
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
    dispatch(setSaveLink('analytics/reviews-by-statuses/file'));
    dispatch(setSaveFilters({
      from: from as string,
      to: to as string,
      regions: regions as number[],
      relatedByOrgans: relatedByOrgans as number[],
      cnapTypes: cnapTypes as string[],
      organTypes: organTypes as string[],
      idfs: idfs as string[],
      isDiia: isDiia as boolean,
      regionLevel: 3
    }));
  }, [pageNumber, reload]);
  useEffect(() => {
    setTableHeaders([{
      Header: 'IDF',
      accessor: 'idf',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.idf}</span>
          </div>
    }, {
      // eslint-disable-next-line react/no-unstable-nested-components
      Header: () => <div className="dashboard-name">
            <span>Назва центру</span>
          </div>,
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.name}</span>
          </div>
    }, {
      Header: 'Тип ЦНАП',
      accessor: 'ascType',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.ascType}</span>
          </div>
    }, {
      Header: 'Кількість відгуків',
      accessor: 'total',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.total}</span>
          </div>
    }, {
      Header: 'Не переглянуто',
      accessor: 'notViewed',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.notViewed}</span>
          </div>
    }, {
      Header: 'Переглянуто',
      accessor: 'viewed',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.viewed}</span>
          </div>
    }, {
      Header: 'Вирішено',
      accessor: 'solved',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.solved}</span>
          </div>
    }, {
      Header: 'Не вирішено',
      accessor: 'notSolved',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.notSolved}</span>
          </div>
    }, {
      Header: 'Не може бути вирішено',
      accessor: 'cantSolve',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.cantSolve}</span>
          </div>
    }, {
      Header: 'Вирішення не потребує',
      accessor: 'noSolutionRequired',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.noSolutionRequired}</span>
          </div>
    }, {
      Header: 'В роботі',
      accessor: 'inProgress',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.inProgress}</span>
          </div>
    }, {
      Header: 'Чекаємо на відповідь респондента',
      accessor: 'expectingReply',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => <div className="rating-col">
            <span style={{
          color: '#000'
        }}>{data.row.original.expectingReply}</span>
          </div>
    }]);
  }, [data]);
  return /*@million jsx-skip*/<ReviewsByStatusesDashboard_1 v0={<ReviewsByStatusesDashboardStyles>
      <div className="counts">
        {counts && Object.entries(counts).map(([key, value]) => <div className="counts__item" key={key}>
            {vocab[key]}: <b>{value}</b>
          </div>)}
      </div>
      <div className="rating">
        {tableHeaders && <Table columns={tableHeaders} data={data} fullWidthColumns={['name']} totalCount={dataCount.current} currentPage={pageNumber} offset={10} loading={loading} pageNumberHandler={value => setPageNumber(value)} fixedHeader />}
      </div>
    </ReviewsByStatusesDashboardStyles>} />;
};