import React, { useEffect, useRef, useState } from 'react';
import { QRControlStyles } from './styles';
import { ApiCompanyField, FieldType, companyApi, exportApi, fieldsApi } from '../../../api';
import { FilterSelectInput } from '../../molecules';
import { CheckMark } from '../../../assets';
import { useParams } from 'react-router-dom';
import { NameCustomField, SubmitButton } from '../../atoms';
import { useDebounce } from '../../../hooks';
import { useAppSelector } from '../../../state';
import { Table } from '../table';
import { QRPublishedNode } from '../../../api/entities/QRPublishedNode';
import { QRModal } from '../modal';
const limit = 10;
export const QRControl = () => {
  const filterParams = useRef<{
    [key: string]: number | string | string[] | number[];
  }>({});
  const [qrModal, setQrModal] = useState<boolean>(false);
  const [qrModalLink, setQrModalLink] = useState<string>('');
  const {
    id
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [tableHeaders, setTableHeaders] = useState<{
    Header: string | any;
    accessor: string;
    Cell?: any;
  }[]>();
  // const [loading, setLoading] = useState<boolean>(true);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  // const [pageNumber, setPageNumber] = useState(1);
  const setFilterParams = (params: any) => {};
  const campaignCountRef = useRef(0);
  const [qrPublishedNodes, setQrPublishedNodes] = useState<QRPublishedNode[]>([]);
  const [filterVariants, setFilterVariants] = useState<{
    [key: string]: {
      id: string;
      value: boolean;
      name: string;
      icon?: any;
    }[];
  }>();
  const [searchParams, setSearchParams] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchParams, 1000);

  // const countFields = useRef<number>(0);
  // const infiniteScrollRef = useRef<HTMLDivElement>(null);
  // const fieldsContainerRef = useRef<HTMLDivElement>(null);

  // const checkIfMoreFields = () => {
  //   if (
  //     infiniteScrollRef.current
  //     && fieldsContainerRef.current
  //     && fields.length
  //   ) {
  //     const infiniteScrollHeight = infiniteScrollRef.current.clientHeight;
  //     const sidebarListHeight = fieldsContainerRef.current.clientHeight;
  //     if (
  //       infiniteScrollHeight >= sidebarListHeight
  //       && fields.length < countFields.current
  //     ) {
  //       setPageNumber((prev) => prev + 1);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   checkIfMoreFields();
  // }, [fields, countFields.current]);

  // useEffect(() => {
  //   if (id) {
  //     setLoading(true);
  //     fieldsApi
  //       .getCompanyFields(
  //         +id,
  //         (pageNumber ? +pageNumber - 1 : 0) * 10,
  //         10,
  //         searchParams,
  //       )
  //       .then((res) => {
  //         setFields((prev) => [...prev, ...res.data]);
  //         countFields.current = res.count || 0;
  //         if (fields.length + res.data.length >= (res.count || 0)) {
  //           setHasMoreFields(false);
  //         }
  //         setLoading(false);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [pageNumber, debouncedSearch]);

  // useEffect(() => {
  //   setPageNumber(1);
  //   setFields([]);
  //   setHasMoreFields(true);
  // }, [searchParams]);

  useEffect(() => {
    if (!loading) {
      setQrPublishedNodes([]);
      setPageNumber(1);
      setReload(!reload);
    }
  }, [reQuery]);
  const applyFilters = (params: any) => {
    if (filterParams.current) {
      // @ts-ignore
      filterParams.current = params;
      setReQuery(e => !e);
    }
  };
  useEffect(() => {
    if (id) {
      companyApi.getQrPublishedNodesFilters({
        id: +id
      }).then(res => {
        const filters: {
          [key: string]: {
            id: string;
            value: boolean;
            name: string;
            icon?: any;
          }[];
        } = {};
        // eslint-disable-next-line guard-for-in
        for (const key in res.data) {
          filters[key] = res.data[key].map((e: string) => ({
            id: e,
            value: false,
            name: e
          }));
        }
        setFilterVariants(filters);
      }).finally(() => {});
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      // console.log({ filterParams: filterParams.current });
      companyApi.getQrPublishedNodes({
        id: +id,
        offset: (pageNumber ? +pageNumber - 1 : 0) * 10,
        limit,
        filters: filterParams.current
      }).then(res => {
        setQrPublishedNodes(prevState => [...prevState, ...res.data]);
        campaignCountRef.current = res.count ?? 0;
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [id, pageNumber, reload]);
  const openLink = (link: string) => window.open(link, '_blank');
  useEffect(() => {
    if (filterVariants) {
      setTableHeaders([{
        Header: '№',
        accessor: 'id',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.id} />
      }, {
        Header: 'IDF',
        accessor: 'idf',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <p>{row.original.idf}</p>
      }, {
        Header: <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants.name}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="name" title="Орган, що утворив" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'name',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameCustomField name={row.original.name} />
      }, {
        Header: 'Назва фронт-офісу',
        accessor: 'node',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <NameCustomField name={row.original.nodeName} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants.state}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="state" title="Область" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'state',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.state} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: <FilterSelectInput resizable search type="checkbox" initiaFilterlValues={filterVariants.region}
        // filtersOpenerStyles={{ minWidth: 220 }}
        columnName="region" title="Район" filterParams={filterParams.current as {
          [key: string]: any;
        }} setFilterParams={setFilterParams} setReQuery={() => setReQuery(!reQuery)} setApply={applyFilters} />,
        accessor: 'region',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (data: any) => <NameCustomField name={data.row.original.region} />
      }, {
        // eslint-disable-next-line react/no-unstable-nested-components
        Header: 'Розміщено QR',
        accessor: 'published',
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({
          row
        }: any) => <div>
              {row.original.published ? <CheckMark /> : <SubmitButton onClick={() => {
            setQrModalLink(row.original.link);
            setQrModal(true);
          }}>
                  Подати звіт
                </SubmitButton>}
            </div>
      }]);
    }
  }, [qrPublishedNodes, filterVariants]);

  // console.log(pageNumber);

  return <QRControlStyles>
      {/* <div className="fields">
        {showSelectedTypes && (
          <div className="fields-select">
            <div className="fields-select-title">
              <h3>Обрати тип поля форми</h3>
              <button
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setField(null);
                  setShowSelectedTypes(false);
                }}
              >
                <SimplePlusIcon width={24} height={24} color="#bababa" />
              </button>
            </div>
            <div className="fields-list">
              {Object.values(FieldType).map((type) => (
                <FieldSelector
                  key={type}
                  type={type}
                  previewText={typeNames[type]}
                  handleClick={() => {
                    setField({ type: type as FieldType });
                    setShowSelectedTypes(false);
                  }}
                />
              ))}
            </div>
          </div>
        )}
         {field && (
          <FieldConstructor
            field={field}
            setFields={setFields}
            setField={setField}
          />
        )}
         {!showSelectedTypes && !field && <h1>Поле не обрано</h1>}
       </div>
       <div className="sidebar">
        <div className="sidebar-title">
          <h4>Список полів суб’єкту моніторингу</h4>
          <button
            type="button"
            onClick={(event) => {
              event.preventDefault();
              setShowSelectedTypes(true);
              setField(null);
            }}
          >
            <SimplePlusIcon width={16} height={16} color="#000" />
          </button>
        </div>
        <div className="sidebar-search">
          <input
            type="text"
            value={searchParams}
            onChange={(event) => {
              setSearchParams(event.target.value);
            }}
            placeholder="Пошук..."
          />
        </div>
        <div
          className="sidebar-fields"
          ref={infiniteScrollRef}
          id="scrollableContainer"
        >
          <InfiniteScroll
            dataLength={fields.length}
            next={() => {
              setPageNumber((prev) => prev + 1);
            }}
            loader={loading ? <Loader margin={10} /> : null}
            hasMore={hasMoreFields}
            scrollableTarget="scrollableContainer"
          >
            <div className="sidebar-list" ref={fieldsContainerRef}>
              {fields
                .filter((item) => {
                  const type = item.type as FieldType;
                  return Object.values(FieldType).includes(type);
                })
                .map((el, index) => (
                  <FieldSelector
                    key={`${el.type}-${index}`}
                    type={el.type as FieldType}
                    previewText={el.question}
                    handleClick={() => {
                      setField({ type: el.type as FieldType, id: el.id });
                      setShowSelectedTypes(false);
                    }}
                  />
                ))}
            </div>
          </InfiniteScroll>
        </div>
       </div> */}
      <div className="container">
        <div className="row">
          {id && <SubmitButton onClick={() => {
          exportApi.exportQrPublishedNodes({
            id: +id,
            filters: filterParams.current
          });
        }}>
              Export
            </SubmitButton>}
        </div>

        {tableHeaders && <Table columns={tableHeaders} data={qrPublishedNodes!}
      // extraFieldsSettings={extraFieldsSettings}
      onClickToRow={data => {
        if (data.published) openLink(data.link);
      }} loading={loading} totalCount={campaignCountRef.current} currentPage={pageNumber} offset={10} pageNumberHandler={(value: number) => setPageNumber(value)} fixedHeader />}
      </div>
      {qrModal && <QRModal btnTitle="Link" title="Посилання на форму контролю розміщення QR" onClose={() => setQrModal(false)} url={qrModalLink} />}
    </QRControlStyles>;
};