import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { Helmet } from 'react-helmet-async';
import { tabsConstructor } from './utils';
import { SubHeader, TransparentButton, CustomTabsMenu, GeneralFormInfo, ClientButton, FormContent, FormLocations } from '../../../components';
import { ConstructorTabKeys } from './TConstructor';
import { ConstructorStyles } from './styles';
export const Constructor = () => {
  const navigate = useNavigate();
  const {
    companyId,
    formId
  } = useParams();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const [selectedTab, setSelectedTab] = useState(tabsConstructor(interfaceLanguage)[0].key);
  const {
    selectedCompany: company
  } = useAppSelector(state => state.company);
  return <ConstructorStyles>
      <Helmet>
        <title>{`Форма ${company?.name || ''}`}</title>
      </Helmet>

      <SubHeader title="Редагування форми" pathArray={[...(company ? [{
      name: company.name!
    }] : [])]}>
        <TransparentButton handleClick={() => navigate(`/companies/${companyId}/edit`)} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_title_edit')} filled />
        <TransparentButton handleClick={() => navigate('/companies')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
      </SubHeader>

      <CustomTabsMenu tabs={tabsConstructor(interfaceLanguage)} selectedTab={selectedTab} setSelectedTab={(key: any) => {
      if ((key === ConstructorTabKeys.form_content || key === ConstructorTabKeys.form_btn || key === ConstructorTabKeys.form_locations) && !formId) {
        alert('Для внесення додаткових налаштувань збережіть основні');
        return;
      }
      setSelectedTab(key as ConstructorTabKeys);
    }} />

      {selectedTab === ConstructorTabKeys.general_info && <GeneralFormInfo />}
      {selectedTab === ConstructorTabKeys.form_content && <FormContent />}
      {selectedTab === ConstructorTabKeys.form_btn && <ClientButton />}
      {selectedTab === ConstructorTabKeys.form_locations && <FormLocations />}
    </ConstructorStyles>;
};