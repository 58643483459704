import React, { useEffect, useMemo, useState } from 'react';
import { SidebarStyles } from './styles';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { AnalyticIcon, CompaniesIcon, FeedbackIcon, IssueIcon, SendIcon, UsersIcon } from '../../../assets';
import { authApi, companyApi, reviewApi } from '../../../api';
import { useWindowSize } from 'usehooks-ts';
import { AccessIcon } from '../../../assets/icons/AccessIcon';
import { usePermissionView } from '../../../hooks';
import { issuesApi } from '../../../api/issues/IssuesApi';
import { accessesApi } from '../../../api/accessesApi/Accesses';
export const Sidebar = React.memo(() => {
  const {
    pathname
  } = useLocation();
  const {
    fullWidth
  } = useAppSelector(state => state.interface);
  const {
    width
  } = useWindowSize();
  const {
    interfaceLanguage
  } = useAppSelector(state => state.languages);
  const {
    data,
    loggedIn
  } = useAppSelector(state => state.user);
  const {
    hasAnyAccess,
    hasAllAccesses
  } = usePermissionView();
  const [reviewsCount, setReviewsCount] = useState<number>(0);
  const [ticketCount, setTicketCount] = useState<number>(0);
  const [accessReviewsCount, setAccessReviewCount] = useState<number>(0);

  // id == 99 equal to Not solved (Не вирішено)
  const fetchData = async () => {
    const issues = await issuesApi.getFilteredTickets({
      companyID: 169,
      statusIDs: [99]
    }, 1, 1);
    setTicketCount(issues.count ?? 0);
    const {
      data: accessReviews
    } = await accessesApi.accessReviewList({
      isManager: true,
      limit: 1,
      offset: 1,
      status: ['pending']
    });
    setTicketCount(issues.count ?? 0);
    setAccessReviewCount(accessReviews.count ?? 0);
  };
  useEffect(() => {
    if (loggedIn) {
      fetchData();
      companyApi.getCompanies().then(res => {
        const companies = res.data.map(el => el.id);
        reviewApi.getReviewCount(companies, [0]).then(res => setReviewsCount(res.data)).catch(error => {
          console.error(error);
        });
      });
    }
  }, [loggedIn, data]);
  const tabs: (lang: string) => {
    [key: string]: any;
  }[] = lang => [...(hasAllAccesses || hasAnyAccess('page-analytics').view ? [{
    key: 'analytic',
    icon: <AnalyticIcon />,
    text: getTranslationByLangOrEng(lang, 'sidebar_analytic'),
    url: '/analytics'
  }] : []), ...(hasAllAccesses || hasAnyAccess('page-analytics').view ? [{
    key: 'companies',
    icon: <CompaniesIcon />,
    text: getTranslationByLangOrEng(lang, 'sidebar_companies'),
    url: '/companies'
  }] : []), ...(hasAllAccesses || hasAnyAccess('page-reviews').view ? [{
    key: 'reviews',
    icon: <FeedbackIcon />,
    text: getTranslationByLangOrEng(lang, 'sidebar_reviews'),
    url: '/reviews'
  }] : []), ...(hasAllAccesses || hasAnyAccess('page-tickets').view ? [{
    key: 'issues',
    icon: <IssueIcon />,
    text: getTranslationByLangOrEng(lang, 'sidebar_tasks'),
    url: '/tickets'
  }] : []), ...(hasAllAccesses || hasAnyAccess('page-users').view ? [{
    key: 'users',
    icon: <UsersIcon />,
    text: getTranslationByLangOrEng(lang, 'sidebar_users'),
    url: '/users'
  }] : []), ...(hasAllAccesses || hasAnyAccess('page-access-request').view ? [{
    key: 'access_request',
    icon: <AccessIcon />,
    text: 'Надання доступів',
    // getTranslationByLangOrEng(lang, 'sidebar_users'),
    url: '/access_requests'
  }] : []), ...(hasAllAccesses || hasAnyAccess('page-massmailer').view ? [{
    key: 'massmailer',
    icon: <SendIcon />,
    text: getTranslationByLangOrEng(lang, 'sidebar_mass_mailings'),
    url: '/massmailer'
  }] : [])];
  const getSidebarMainExtraStyles = () => pathname.startsWith('/auth') ? {
    width: 0
  } : fullWidth ? {
    width: 192
  } : width > 768 ? {
    width: 56,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center'
  } : {
    width: 0,
    paddingLeft: 0,
    paddingRight: 0,
    alignItems: 'center'
  };
  return <SidebarStyles style={{
    ...getSidebarMainExtraStyles()
  }}>
      <ul>
        {tabs(interfaceLanguage).map((tab, index) => <li key={tab.key} className={pathname.startsWith(tab.url) ? 'active' : ''}>
            <Link to={tab.url} role="button" aria-label={tab.text}>
              {tab.icon}
              {fullWidth && <span>{tab.text}</span>}
              {tab.key === 'reviews' && <b className={fullWidth ? 'count' : 'count count-abs'}>
                  {reviewsCount > 99 ? '99+' : reviewsCount}
                </b>}
              {tab.key === 'issues' && ticketCount > 0 && <b className={fullWidth ? 'count' : 'count count-abs'}>
                  {ticketCount > 99 ? '99+' : ticketCount}
                </b>}
              {tab.key === 'access_request' && accessReviewsCount > 0 && <b className={fullWidth ? 'count' : 'count count-abs'}>
                  {accessReviewsCount > 99 ? '99+' : accessReviewsCount}
                </b>}
            </Link>
          </li>)}
      </ul>
      {fullWidth && <p>
          diia.gov.ua 2019 - {new Date().getFullYear()}. Всі права захищені.
        </p>}
    </SidebarStyles>;
});