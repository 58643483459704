import React, { useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HeaderStyles } from './styles';
import { useAppDispatch, useAppSelector, setSidebarFullWidth, changeUser, setGrayMode, increaseFont, decreaseFont } from '../../../state';
import { ContrastIcon, InterfaceDecrease, InterfaceIncrease, LogoFillIcon, LogoIcon, MenuIcon, ProfileIcon, SelectArrow } from '../../../assets';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useWindowSize } from 'usehooks-ts';
import { TransparentButton } from '../transparentButton';
interface LanguageMenuProps {
  handleClose: () => void;
}
export const Header = React.memo(() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    pathname
  } = useLocation();
  const {
    data: user,
    loggedIn
  } = useAppSelector(state => state.user);
  const {
    grayMode,
    fontScale
  } = useAppSelector(state => state.interface);
  const {
    fullWidth
  } = useAppSelector(state => state.interface);
  const {
    width
  } = useWindowSize();
  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false);
  const topMenuRef = useRef<HTMLDivElement>(null);
  const tabs = [{
    key: 'profile',
    text: 'Профіль',
    url: '/profile'
  }];
  function handleMenuButton() {
    dispatch(setSidebarFullWidth(!fullWidth));
  }
  const logout = () => {
    dispatch(changeUser(null));
    const cookies: string[] = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('access_token_end_date');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expires_at');
    sessionStorage.clear();
  };
  const changeContrast = () => {
    dispatch(setGrayMode(!grayMode));
  };
  useClickOutside(topMenuRef, () => {
    setOpenProfileModal(false);
  });
  return <HeaderStyles>
      <div className={width > 768 ? 'leftPart' : 'leftPart compact'}>
        {!pathname.startsWith('/auth') && <button className="menuButton" type="button" onClick={handleMenuButton} aria-label="Меню">
            <MenuIcon />
          </button>}
        <Link to="/" className="logo">
          <LogoIcon width={38} height={38} />
          <LogoFillIcon width={38} height={38} />
        </Link>
      </div>

      <div className="user" ref={topMenuRef}>
        <div className="user-select">
          {!pathname.startsWith('/auth') && loggedIn && <>
            <span
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} onClick={() => setOpenProfileModal(!openProfileModal)} aria-label="Меню користувача" onKeyDown={e => {
            if (e.key === 'Enter') {
              setOpenProfileModal(!openProfileModal);
            }
          }}>
              {user?.name}
            </span>
            <div className={`user-select-arrow ${openProfileModal ? 'active' : ''}`}>
              <SelectArrow />
            </div>
            <ProfileIcon />
          </>}
          <button type="button" className="user-contrast" onClick={changeContrast} aria-label="Контрастність">
            <ContrastIcon />
          </button>
          <button type="button" className="user-interface" onClick={() => dispatch(increaseFont())} aria-label="Збільшення шрифта">
            <InterfaceIncrease color={fontScale >= 0.8 ? 'rgba(0, 0, 0, 0.4)' : '#000'} />
          </button>
          <button type="button" className="user-interface" onClick={() => dispatch(decreaseFont())} aria-label="Зменшення шрифта">
            <InterfaceDecrease color={fontScale <= 0.4 ? 'rgba(0, 0, 0, 0.4)' : '#000'} />
          </button>
        </div>
        {openProfileModal && <div className="user-list">
            {tabs.map(el => <button type="button" key={el.key} onClick={() => {
          navigate(el.url);
          setOpenProfileModal(!openProfileModal);
        }} className="user-link" tabIndex={0} aria-label={el.text}>
                {el.text}
              </button>)}
            <button type="button" onClick={logout} className="user-link" aria-label="Вийти">
              Вийти
            </button>
          </div>}
      </div>
    </HeaderStyles>;
});